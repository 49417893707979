import React from 'react'
import clsx from 'clsx'
import { PageProps } from 'gatsby'
import { Layout } from '../../../components/layout'
import { SEO } from '../../../components/seo'
import { Menu } from '../../../components/menu'

import * as styles from '../../../styles/pages/solutions.module.scss'
import mainHeader from '../../../images/solutions/bpm/aderenciaSistemas.jpg'

import { Contact } from '../../../components/contact/contact'
import { Footer } from '../../../components/footer'
import { FaqSection } from '../../../components/faq'
import { MethodologySection } from '../../../components/methodology'
import { SliderCustomers } from '../../../components/sliderCustomers/sliderCustomers'
import { StatisticsSection } from '../../../components/statistics'
import { bpmList } from '../../../utils/customersList'
import BreadcrumbComponent from '../../../components/breadcrumb'

const faqItems = [
  {
    question: 'Por que contratar o serviço de aderência de sistemas?',
    answer:
      'Antes de implementar um novo sistema, é preciso analisar o nível de aderência deste aos processos e particularidades de cada empresa. É comum que as empresas de software ofereçam este serviço, porém, não se trata de uma avaliação imparcial, já que a empresa deseja vencer a concorrência. Contratar uma consultoria, permite uma análise de aderência imparcial, técnica e economicamente fundamentada.',
  },
  {
    question: 'Quanto tempo leva o projeto de consultoria?',
    answer:
      'Projetos de análise de aderência de sistemas comumente demandam de 03 a 06 meses de execução. O cronograma depende de variáveis como: o escopo do projeto, quantidade de processos a serem analisados, quantidade de sistemas analisados e disponibilidade da equipe interna para trabalhar com a consultoria. Se considerar a fase de gestão da implantação, o prazo pode ser ainda maior.',
  },
  {
    question: 'Como solicitar um orçamento para um projeto de consultoria?',
    answer:
      'Informe seus dados no formulário acima para receber o contato de um Especialista em Processos. Um especialista retornará o contato para coletar informações, através de uma reunião de diagnóstico. Mais do que um orçamento, é necessário entender qual o momento atual da empresa, quais são os principais desafios, para que então possamos oferecer a melhor solução.',
  },
  {
    question: 'Como saber se o projeto trará ROI para a minha empresa?',
    answer:
      'Projetos de consultoria são investimentos, e não despesas, portanto devem proporcionar ganhos para a organização. Ao mapear processos e analisar a aderência destes aos sistemas pretendidos para contratação, é possível proporcionar maior segurança no escopo contratado e eliminar redundâncias e customizações desnecessárias, reduzindo significativamente custos e riscos na etapa de implantação.',
  },
]

const methodologyItems = [
  {
    count: 1,
    title: 'Mapeamento',
    description: (
      <>
        O primeiro passo para análise de aderência de sistema é o mapeamento dos
        processos organizacionais atuais (AS IS). Além dos fluxogramas, são
        identificados os atributos dos processos, como: objetivos, metas,
        procedimentos, regras de negócio e nível de maturidade.
      </>
    ),
  },
  {
    count: 2,
    title: 'Melhoria',
    description: (
      <>
        Após mapeados os processos, são identificadas oportunidades de melhoria.
        Esta etapa ajuda a não sistematizar erros, como: retrabalhos, excesso de
        hierarquia de aprovações, dentre outros. Em seguida, é desenhado o
        processo já otimizado, também chamado de TO BE.
      </>
    ),
  },
  {
    count: 3,
    title: 'Elaboração de RFP',
    description: (
      <>
        Através de um cálculo matemático de quantas atividades do processo cada
        sistema atende, obtém-se um percentual de aderência de cada fornecedor
        de software aos processos da empresa cliente. Esta informação, bem como
        os mapeamentos são anexados ao pedido de proposta (RFP).
      </>
    ),
  },
  {
    count: 4,
    title: 'Gestão da implantação',
    description: (
      <>
        Além da aderência do sistema aos processos de negócio, para a escolha do
        software são analisadas as propostas recebidas pelo cliente, baseando-se
        em critérios técnicos, reputação do fornecedor, experiência, suporte e
        preços. Após contratação, é feita a gestão da implantação por meio de
        metodologias ágeis.
      </>
    ),
  },
]

const featuresItems = [
  {
    title: `Aderência de sistemas`,
    description: (
      <>
        O sistema ERP é peça fundamental para a tomada de decisão na gestão de
        um negócio. No entanto, para que o sistema se mostre vantajoso para
        otimizar processos, aprimorar a gestão e maximizar o lucro, é necessário
        que haja aderência entre o sistema escolhido e a forma como a empresa
        trabalha.
      </>
    ),
  },
  {
    title: `O que fazer antes de implantar`,
    description: (
      <>
        Antes de escolher um sistema ERP para implantação ou migração, é preciso
        ter uma visão clara dos processos de negócio. Estes devem ser mapeados,
        otimizados e após, feita a análise de aderência ao software que se
        deseja implementar. Sem esta análise, é possível que o sistema fique
        subutilizado, aumentando o risco da operação.
      </>
    ),
  },
  {
    title: `Independência e imparcialidade`,
    description: (
      <>
        Para escolher o melhor sistema de gestão é preciso fazer uma análise
        independente e imparcial, não vinculada ao fornecedor do software. É boa
        prática a contratação de uma consultoria especializada em processos,
        como a High Jump, para mapear os processos, otimizá-los, analisar sua
        aderência ao software e, após, gerenciar a sua implementação.
      </>
    ),
  },
  {
    title: `Redução de custos e riscos`,
    description: (
      <>
        Ao contratar uma consultoria para análise de aderência antes da
        implementação do sistema, além de garantir maior aderência do sistema
        aos processos, reduz custos com customizações e riscos do projeto de
        implantação, já que o mapeamento dos processos é usado como blueprint
        para a empresa de software.
      </>
    ),
  },
]

const AderenciaSistemasPage: React.FC<PageProps> = ({ location }) => {
  const { pathname } = location
  return (
    <Layout location={location}>
      <SEO title="High Jump - Aderência de sistemas" />
      <div className={styles.solutions}>
        <Menu mainFolder={pathname} />
        <main>
          <section className={clsx(styles.intro, styles.singleTitle)}>
            <img src={mainHeader} alt="High jump Consulting" />
            <div className={clsx(styles.mainTitle)}>
              <h1>Como escolher o sistema de gestão ideal</h1>
              <p>
                Conheça a consultoria para análise de aderência de sistemas para
                escolher o ERP ideal de acordo com o seu modelo de negócio, seja
                para primeira implantação ou migração.
              </p>
            </div>
          </section>
          <div className={styles.container}>
            <BreadcrumbComponent
              replacedMainPath="Gestão por processos de negocio BPM"
              replacedLabel="Aderência de sistemas"
            />
            <div className={styles.intention}>
              <div className={styles.title}>
                <h1>Nosso Propósito</h1>
                <span></span>
                <p>
                  Analisar a aderência de sistemas ERP/CRM para embasar a tomada
                  de decisões e aprimorar a gestão empresarial.
                </p>
              </div>
              <div className={styles.mainFeatures}>
                <div className={styles.listItems}>
                  {featuresItems.map((item, key) => (
                    <div className={styles.item} key={key}>
                      <h4>{item.title}</h4>
                      <p>{item.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <MethodologySection
              items={methodologyItems}
              descriptionSection="Conheça os 04 principais passos de nossa metodologia para escolher o software de gestão ideal para o seu negócio."
            />
          </div>

          <div className={styles.cases}>
            <div className={styles.title}>
              <h1>Quem conhece, confia!</h1>
            </div>
            <SliderCustomers customers={bpmList} />
          </div>

          <div className={styles.statistics}>
            <StatisticsSection />
          </div>

          <div className={styles.container}>
            <div className={styles.features}>
              <div className={styles.title}>
                <h1>Diferenciais</h1>
              </div>
              <div className={styles.items}>
                <div className={styles.div1}>
                  <h2>Equipe multidisciplinar</h2>
                  <p>
                    Especialistas por área, trazendo as melhores práticas de
                    mercado
                  </p>
                </div>
                <div className={styles.div2}>
                  <h2>Especialistas certificados</h2>
                  <p>
                    Em gestão por processos (ABPMP, OCEB) e gestão de projetos
                    (PMI)
                  </p>
                </div>
                <div className={styles.div3}>
                  <h2>Confiança no resultado</h2>
                  <p>Quantificando e qualificando ganhos de projeto</p>
                </div>
                <div className={styles.div4}>
                  <h2>Modalidade híbrida</h2>
                  <p>
                    Execução presencial e/ou remota em comum acordo com o
                    cliente
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Contact locationPath={pathname} />
          <FaqSection items={faqItems} />
          <Footer />
        </main>
      </div>
    </Layout>
  )
}

export default AderenciaSistemasPage
